(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/image-v2/assets/javascripts/helpers/hbs-helpers.js') >= 0) return;  svs.modules.push('/components/content/image-v2/assets/javascripts/helpers/hbs-helpers.js');

'use strict';

var _require;
const loggerId = 'components:content:image-v2';
const cmsImagePrefix = '/cms/images';
const {
  isServer
} = svs;
const {
  svsuid,
  getFileFormat
} = svs.utils;
const trinidad = isServer && ((_require = require('trinidad-core')) === null || _require === void 0 ? void 0 : _require.core);
const {
  toArrayOrNull
} = isServer ? require("".concat(__dirname, "/to-array-or-null.es6")) : svs.content.image_v2.helpers;
const logger = isServer ? trinidad.logger(loggerId) : new svs.core.log.Logger(loggerId);

const getUrlWithCorrectHost = (url, config) => {
  if (!url) {
    return '';
  }
  return /^(https?:)?\/\/.*/.test(url) ? url : "".concat(config.options.cdn2).concat(url);
};

const getUrlWithFilledPlaceholders = (url, width, extension) => {
  const picassoData = "".concat(width, "/").concat(extension);
  url = url.replace(RegExp("^".concat(cmsImagePrefix)), "".concat(cmsImagePrefix, "/").concat(picassoData));
  return url;
};

const getWithFilledPlaceholders = (url, width, extension) => {
  url = url.replace('{width}', width);
  url = url.replace('{extension}', extension);
  return url;
};

const getUrl = (image, extension, width) => {
  var _image$url, _extension;
  let url;
  url = (image === null || image === void 0 || (_image$url = image.url) === null || _image$url === void 0 ? void 0 : _image$url.replace(/-({width}|%7Bwidth%7D)/g, '')) || '';
  url = getWithFilledPlaceholders(url, width, extension);
  if (image !== null && image !== void 0 && image.isPreview && !extension) {
    return url;
  }
  extension = ((_extension = extension) === null || _extension === void 0 ? void 0 : _extension.replace(/\./g, '')) || 'original';
  width = image !== null && image !== void 0 && image.originalImage ? 'original' : width;
  return getUrlWithFilledPlaceholders(url, width, extension);
};

const getSrcsetString = (image, extension, width, config) => {
  var _image$availableForma;
  let output;

  output = getUrl(image, extension || ((_image$availableForma = image.availableFormats) === null || _image$availableForma === void 0 || (_image$availableForma = _image$availableForma[0]) === null || _image$availableForma === void 0 ? void 0 : _image$availableForma.extension) || 'webp', width);

  output = getUrlWithCorrectHost(output, config);

  output = "".concat(output).concat(isNaN(width) ? ' ' : " ".concat(width, "w"));
  return output;
};

function registerHelpers(hbs, config) {
  const handlebarsHelpers = {
    getUrlWithCorrectHost: (url, config) => getUrlWithCorrectHost(url, config),
    contentImageHeight: context => {
      const {
        height,
        width
      } = context || null;
      const fallbackHeight = 43.75;
      return width && height ? height / width * 100 : fallbackHeight;
    },
    getUniqueSelector: () => "image-".concat(svsuid()),
    getSrcset: (image, extension) => {
      var _image$availableWidth;
      const srcsetArray = (image === null || image === void 0 || (_image$availableWidth = image.availableWidths) === null || _image$availableWidth === void 0 ? void 0 : _image$availableWidth.map(width => getSrcsetString(image, extension, width, config))) || getUrlWithCorrectHost(getUrl(image, extension), config);
      return new hbs.SafeString(srcsetArray.toString());
    },
    setVar(name, value) {
      this[name] = value;
    },
    getSourceElementsData: image => {
      var _getFileFormat;
      if (!image) {
        logger.warn('No image data provided.');
        return;
      }
      const {
        loadingLazy,
        url,
        media,
        sizes: imageSizes
      } = image;

      const originalFormat = {
        extension: 'original',
        mimeType: (_getFileFormat = getFileFormat(url)) === null || _getFileFormat === void 0 ? void 0 : _getFileFormat.mimeType
      };

      const formats = originalFormat.mimeType === 'image/svg+xml' ? [originalFormat] : [getFileFormat('webp'), originalFormat];
      const sourcesData = formats.map(_ref => {
        let {
          extension,
          mimeType
        } = _ref;
        const {
          getSrcset
        } = handlebarsHelpers;
        const id = svsuid();
        const dataSrc = loadingLazy ? getSrcset(image, extension) : null;
        const srcSet = !loadingLazy ? getSrcset(image, extension) : null;
        const type = mimeType;
        const sizesFallback = '100vw';
        const sizes = imageSizes || sizesFallback;
        return {
          id,
          dataSrc,
          media,
          sizes,
          srcSet,
          type
        };
      });
      return sourcesData;
    },
    stringifyImagesObject: input => {
      const imagesArray = toArrayOrNull(input);
      if (imagesArray) {
        const customImagesArray = imagesArray.map(item => item ? {
          aspectRatio: item.aspectRatio,
          dominantColor: item.dominantColor,
          media: item.media
        } : undefined);
        return JSON.stringify(customImagesArray);
      }
      return null;
    },
    setStyleElement(selector, imagesObject) {
      if (svs.isBrowser) {
        const {
          setStyleElement
        } = svs.content.image_v2.helpers.multiImageStyleElement;
        setStyleElement(selector, imagesObject);
      }
    },
    getNoscriptUrl(image, images, noscriptUrl, isMultiImage) {
      const getWidestItem = items => (items === null || items === void 0 ? void 0 : items.reduce((prev, curr) => (prev === null || prev === void 0 ? void 0 : prev.width) > (curr === null || curr === void 0 ? void 0 : curr.width) ? prev : curr, {
        width: 0
      })) || null;
      const $noscriptUrl = isMultiImage ? noscriptUrl : image === null || image === void 0 ? void 0 : image.noscriptUrl;

      const getMultiImageFallback = () => getWidestItem(images && Object.values(images));
      const getFallbackUrl = () => {
        var _fallbackImage$availa, _fallbackImage$availa2;
        const fallbackImage = isMultiImage ? getMultiImageFallback() : image;
        const highestWidth = fallbackImage !== null && fallbackImage !== void 0 && (_fallbackImage$availa = fallbackImage.availableWidths) !== null && _fallbackImage$availa !== void 0 && _fallbackImage$availa.includes('original') || fallbackImage !== null && fallbackImage !== void 0 && fallbackImage.originalImage ? 'original' : Math.max(...((fallbackImage === null || fallbackImage === void 0 ? void 0 : fallbackImage.availableWidths) || [0]));
        const extension = fallbackImage === null || fallbackImage === void 0 || (_fallbackImage$availa2 = fallbackImage.availableFormats) === null || _fallbackImage$availa2 === void 0 || (_fallbackImage$availa2 = _fallbackImage$availa2[0]) === null || _fallbackImage$availa2 === void 0 ? void 0 : _fallbackImage$availa2.extension;
        return getUrl(fallbackImage, extension, highestWidth);
      };
      const url = $noscriptUrl || getFallbackUrl();
      return getUrlWithCorrectHost(url, config);
    }
  };

  for (const helperName in handlebarsHelpers) {
    if (helperName in handlebarsHelpers) {
      hbs.registerHelper(helperName, handlebarsHelpers[helperName]);
    }
  }
}
if (isServer) {
  const {
    core
  } = require('trinidad-core');
  registerHelpers(require('hbs'), core.config);
} else {
  var _svs$core;
  const config = {
    options: (_svs$core = svs.core) === null || _svs$core === void 0 || (_svs$core = _svs$core.config) === null || _svs$core === void 0 || (_svs$core = _svs$core.data) === null || _svs$core === void 0 ? void 0 : _svs$core.svsconfig
  };
  registerHelpers(Handlebars, config);
}

 })(window);